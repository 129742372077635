import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import BigPromoPicture from '../forsite.png'
import GoogleLink from '../getitgoogle.png'
import AppleLink from '../appstore.png'
import StoreLogo from '../updatedStoreLogo.png'


const OtherHome = () => {
	return (

		<Container>
  <Row className="justify-content-md-center p-5">
    <Col xs lg="2">
      <a href='https://play.google.com/store/apps/details?id=com.pl47productions.tensecondsatatimeP'><img src={GoogleLink} alt=''></img></a>
    </Col>




    <Col xs lg="2">
      <a href='https://apps.apple.com/us/app/10-seconds-at-a-time/id1288218599?ls=1'><img src={AppleLink} alt=''></img></a>
    </Col>


  </Row>


  <Row>

    <Col>


     <img src={StoreLogo} alt="Sign Up Now" class="hero-img responsive-img"></img>

    <h1 class='orange-text'><b>The moral support app</b></h1>
<p class='black-text'>10 seconds at a Time is a simple way to send moral support messages 
to your friends and relatives facing difficult times, in a non-intrusive, motivation inducing way. 
The only way to view a message is to start and finish the 10 second timer. 

</p>

<h1 class='orange-text'><b>Why 10 seconds?</b></h1>
<p class='black-text'>To quote the Unbreakable Kimmy Schmidt, “You can stand anything for 10 seconds…Then you just start on a new 10 seconds.”
Experiencing long periods of intense pain, suffering, or emotional agony can often create feelings of defeat, isolation, and depression.
However, by breaking down our indefinite suffering into something definite, say 10 second periods, we can begin to reclaim the feeling of 
success, hope, and accomplishment. These feelings can lead to an increase in dopamine levels, which can lead to better moods, energy, and 
motivation.

As such, 10 Seconds at a Time was created to promote and encourage fighting small battles to achieve big wins. 
As you will see in the app, by starting and finishing the 10 second timer, not only will you have reclaimed a bit of hope, 
you will be rewarded with a supportive message either from your friends, family, or from our public support channel.</p>





</Col>


   
    <Col xs lg="5">
      <img src={BigPromoPicture} alt="Sign Up Now" class="hero-img responsive-img"></img>
    </Col>
  </Row>



</Container>
		)
}

export default OtherHome;