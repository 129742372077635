import React, { Component } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


class NavigationBar extends Component {

	state = {
		showModal: false
	}


	render() {

	const signUpModal = () => {
		if (this.state.showModal ? this.setState({
			showModal: false
		})

			: this.setState({
				showModal: true
			})

			);
	}

		return(
			<div>
				<Navbar bg="orange" expand="lg" text='white'>
  					<Navbar.Brand><Link to ='/home'>10 Seconds at a Time</Link></Navbar.Brand>

 					 <Navbar.Toggle aria-controls="basic-navbar-nav" />
 						 <Navbar.Collapse id="basic-navbar-nav">
 						 	<Nav className='mr-auto'>
  									<Nav.Link ><Link to='/privacypolicy'>Privacy Policy</Link></Nav.Link>
  									<Nav.Link><Link to='/terms'>Terms of Service</Link></Nav.Link>
									  <Nav.Link><Link to='/contact'>Contact</Link></Nav.Link>
  							</Nav>


  						</Navbar.Collapse>
				</Navbar>
		
		</div>


			);
	}
}

export default NavigationBar;