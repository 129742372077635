import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/theNavbar.js'

import 'tachyons';

import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './components/Home.js'
import PrivacyPolicy from './components/PrivacyPolicy.js'
import TermsOfService from './components/TermsOfService.js'
import OtherHome from './components/OtherHome.js'
import BaseNavbar from './components/BaseNavbar.js'
import  Contact  from './components/Contact.js';

class App extends Component {
  render() {
    return (

  <div>
  <BrowserRouter>
      <Navbar />
          <Switch>
          <Route exact path='/' component={OtherHome} />
        
          <Route path='/home' component={OtherHome} />
          <Route path='/privacypolicy' component={PrivacyPolicy} />
          <Route path='/terms' component={TermsOfService} />
          <Route path ='/contact' component={Contact} />

         </Switch> 

       
        </BrowserRouter>
    
     

      


    
  </div>


      );
  }
}



export default App;
