import React, { Component } from 'react'

class PrivacyPolicy extends Component  {
	render() {
		return (
				<div class='container'>
				<h1>Privacy Policy</h1>
				<p>

				Date of Last Update: November 15, 2017



1. Introduction



PL47 Productions, LLC (“PL47 Productions”) is the owner of the “10 Seconds At A Time” mobile application (the “App”). PL47 Productions can be contacted by mail at 3087 Affirmed Drive, North Bend, OH 45052 by phone at 513-259-1152, or by e-mail at support@pl47productions.com. This Privacy Policy discloses PL47 Productions’ information practices for the App, including what type of personal identifiable information is requested in order to register, how the information is used, and with whom the information is shared. Note that other PL47 Productions’ websites and mobile apps may be governed by privacy notices containing different information practices applicable to those sites and applications.



2. Personal Information That May Be Collected



(a) Anonymous App Visits. In general, you can visit on the App without disclosing OR revealing any personal information.



(b) App Registration. In order to upload a video or other content to the App, visitors must register and create an account. In order to create an account, PL47 Productions may need to know a user’s name and phone number. PL47 Productions may request additional personal information necessary to establish and maintain a user’s account. It is PL47 Productions’ intent to inform you know before PL47 Productions collects personal information.



(c) Information from Children. PL47 Productions does not collect or maintain information from users actually known to be under the age of 18, and no part of the App is structured to attract anyone under the age of 18.



(d) Lost or Stolen Information. If a user’s password is compromised, the user should promptly notify PL47 Productions in order to enable us to cancel the compromised information and to update its records with a changed password.



(e) Links to Other Websites or Mobile Applications. The App may contain links to other websites or mobile applications. PL47 Productions is not responsible for the privacy practices or the content of those other websites or applications.



3. Uses Made of the Information



(a) Limited Uses Identified. Without the user’s prior consent, PL47 Productions will not use your personal identifiable information for any purpose other than that for which it is submitted. PL47 Productions uses personal identifiable information to reply to inquiries, handle complaints, provide operational notices, program record-keeping, statistical analysis of user behavior, product development, content improvement, etc.



(b) Marketing Uses. The information you provide to the App may also be collected, used, analyzed and/or processed by PL47 Productions, or selected third parties on PL47 Productions’ behalf, for marketing and other business purposes. Before PL47 Productions uses the information, however, PL47 Productions will notify customers and offer customers the opportunity opt-out if a customer wishes not to have personal identifying information used in this way. [Is this accurate[PL1] ?]



(c) Online Advertising. Some companies that help PL47 Productions deliver interactive on-line advertising, such as banner ads, may collect and use information about our users to help PL47 Productions better understand the types of advertising or promotions that are most appealing to users. After it is collected, the information is aggregated so it is not identifiable to a specific individual.



4. Disclosure of the Information



(a) Within Corporate Organization. PL47 Productions may share your personal information within the PL47 Productions corporate organization, and may transfer the information to countries in the world where PL47 Productions conducts business. Some countries may provide less legal protection for customer personal information.



(b) Mergers and Acquisitions. Circumstances may arise where for business reasons, PL47 Productions decides to sell, buy, merge or otherwise reorganize its businesses in the United States or some other country. Such a transaction may involve the disclosure of personal identifying information to prospective or actual purchasers, and/or receiving such information from sellers. In such transactions, customer information is generally one of the transferred business assets.



(c) Agents. PL47 Productions employs or engages other companies and individuals to perform business functions on behalf of PL47 Productions. These persons are provided with personal identifying information required to perform their functions, but are prohibited by contract from using the information for other purposes. These persons engage in a variety of functions which include, but are not limited to, fulfilling orders, removing repetitive information from customer lists, analyzing data, providing marketing assistance, and providing customer services.



(d) Marketing Analysis by Third Parties. PL47 Productions reserves the right to disclose to third parties personal information about customers for marketing analysis; however, any information disclosed will be in the form of aggregate data that does not describe or identify an individual customer.



(e) Disclosure to Governmental Authorities. Under certain circumstances, personal information may be subject to disclosure pursuant to a judicial or other government subpoenas, warrants or orders.



5. Use of Computer Tracking Technologies



(a) No Tracking of Personal Information. The App is not set up to track, collect or distribute personal information not entered by users. Through access logs, PL47 Productions does collect certain kinds of non-identifying usage data, such as the number of hits and visits to the App. This information is used for internal purposes by technical support staff for research and development, user analysis and business decision-making, all of which provides better services to the public. The statistics garnered, which contain no personal information and cannot be used to gather such information, may also be provided to third parties.



(b) Collection of Non-Identifiable Information. PL47 Productions may collect non-identifiable information from user visits to the App in order to provide better customer service. Examples of such collecting include: traffic analysis, such as tracking numbers of visitors; measuring visitor activity on the App; system administration; user analysis; and business decision-making. Such information is sometimes known as "clickstream data." PL47 Productions or its contractors may use this data to analyze trends and statistics.



(c) Collection of Personal Information. PL47 Productions collects personal identifying information from users during registration. PL47 Productions may extract some personally identifying information about that transaction in a non-identifiable format and combine it with other non-identifiable information, such as clickstream data. This information is used and analyzed only at an aggregate level (not at an individual level) to help PL47 Productions understand trends and patterns. This information is not reviewed at an individual level.



(d) Online Tracking. Online tracking technology enables website and mobile application operators to collect personal information about consumers as they move across websites and other online services. Most browsers incorporate “do not track” (DNT) features in their privacy settings. When enabled, a DNT signal or request informs a website or mobile application operator that the visitor does not wish to be tracked online. The App is currently designed only for tracking movement within the App. PL47 Productions does not honor DNT requests, and does not treat visitors who make DNT requests differently from visitors who do not make DNT requests. PL47 Productions also does not differentiate between visitors who make DNT requests and visitors who do not make such requests, when it comes to the collection or usage of personal information. There are no third parties that conduct online tracking on the App.



6. Information Security



(a) Commitment to Online Security. PL47 Productions employs physical, electronic and managerial procedures to safeguard the security and integrity of personal information.



(b) No Liability for Acts of Third Parties. PL47 Productions will exercise all reasonable efforts to safeguard the confidentiality of customer personal information. However, transmissions protected by industry standard security technology and implemented by human beings cannot be made absolutely secure. Consequently, PL47 Productions shall not be liable for unauthorized disclosure of personal information due to no fault of PL47 Productions including, but not limited to, errors in transmission and unauthorized acts of PL47 Productions staff and/or third parties.



7. Privacy Policy Changes



This privacy notice was last updated on November 15, 2017. PL47 Productions reserves the right to change its privacy policy at any time. A notice of any material change will be posted on the App for thirty days following the implementation of such change.



8. Accountability



(a) Questions, Problems and Complaints. If you have a question about this policy statement, or a complaint about PL47 Productions compliance with this privacy policy, you may contact PL47 Productions by e-mail support@pl47productions.com.



(b) Terms of Use. If a user chooses to register an account to use the App, such action is deemed acceptance of our privacy practices described in this policy statement. Any dispute over privacy between a user and PL47 Productions is subject to the provisions of this notice and to PL47 Productions’ Terms of Service, which is incorporated herein and which can be read at https://www.tensecondsatatime.com.


				</p>

				</div>

			);
	}
}

export default PrivacyPolicy;