import React,  {Component } from 'react';



class Contact extends Component {
    render(){
        return(
            <div className='container'>
                <h1>Contact Information</h1>
                <p>PL47Productions can be contacted at: </p>
                <a href="mailto:plysaght47@pl47productions.com?subject=contactquestion">plysaght47@pl47productions.com</a>  
            </div>
        )
    }
}

export default Contact;