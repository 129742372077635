import React, { Component } from 'react'


class TermsOfService extends Component {
	render() {
		return (
				<div class='container'>
				<h1> Terms of Service </h1>

				<p class='black-text'>
				This document contains the Terms of Service for the “10 Seconds At A Time” mobile application (the “App”). 
				Please read this document carefully, as it contains important information relating to your use of the App.

				Last updated on November 15, 2017. These Terms of Service are effective immediately.



1. Acceptance of Terms



PL47 Productions, LLC (“PL47 Productions”) is the owner of the App. By using its App, you are agreeing to the following Terms of Service ("Terms") whether or not you are a registered customer of the App. PL47 Productions may update the Terms from time to time without notice to you. However, you can always find the latest Terms at https://www.tensecondsatatime.com.  In addition, when using the App, you shall be subject to any posted policies, guidelines or rules applicable to the App. All such policies, guidelines and rules are incorporated by reference into the Terms. You further agree, except as otherwise expressly provided in these Terms, there shall be no third party beneficiaries to this agreement. IF YOU DO NOT AGREE TO THE CURRENT TERMS, PLEASE DO NOT USE THE APP, SINCE YOUR USE OF IT CONSTITUTES ACCEPTANCE OF ALL OF THE CURRENT TERMS FOR ITS USE.



2. Description of Service



The App is a mobile application that enables users to create and post short videos, photos, and written messages in support of persons that are struggling with some form of adversity in life, such as an illness. Registered users can create content, select favorites, edit, rate, comment and view other people\’s content. Non-registered users can browse topics, profiles and content of interest. Registered users also can delete content on public channels if they feel such content is inappropriate, or block a sender. Any new features, services and enhancements to the App are subject to the Terms. THE APP IS PROVIDED "AS IS" AND PL47 PRODUCTIONS ASSUMES NO RESPONSIBILITY FOR TIMELINESS, MIS-DELIVERY, OR FAILURE TO STORE ANY COMMUNICATION, VIDEO, WRITING, IMAGE OR PERSONALIZATION SETTINGS.



All users must abide by the Terms. If a user fails to follow any of the guidelines and/or rules of behavior, PL47 Productions can discontinue their ability to use the App. In addition, we have the right to delete any piece of content, as well as any topic or profile we find objectionable.



You are totally responsible for any activity that takes place under your name and password. If you become aware of any unauthorized use of your username and/or password, it is your responsibility to notify PL47 Productions immediately. It is up to you to maintain the confidentiality of your password and username.



You understand that you may receive business-related communications from PL47 Productions such as service announcements and account administrative notices. You agree that these communications are not "unsolicited commercial email advertisements" and thus you agree to receive them and you will not be able to opt out of receiving such communications.



As a user of the App you understand and agree that the App may include advertisements. You agree that such advertisements are not "unsolicited commercial email advertisements" and thus you agree to receive them and you will not be able to opt out of receiving such communications. You understand and agree that your correspondence or business dealings with, or participations in promotions of, advertisers found on or through the App, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and the advertiser. You agree that PL47 Productions shall not be responsible or liable for any loss or damage incurred as the result of such dealings or as the result of the presence of such advertisers on the App.



You understand that you are responsible for obtaining access to the App and that access may involve third party fees. You are totally responsible for obtaining such access and paying those fees.



Unless explicitly stated otherwise, any new features that augment or enhance the App, including the release of new PL47 Productions properties, shall be subject to the then current Terms.



You must be at least 13 18 years of age to access and view the App. If you are 13 years or younger than 18 years old, please do not use this App for any purpose.



3. Rules of Behavior



You are solely responsible for your own communications and content, including any data, images, graphics, text, audio, video, email, links, screen names you post to the App. You agree to use the App to post and receive communications and content that are legal and proper.



You must not abuse, defame, harass, stalk, threaten, intimidate, or otherwise violate the legal rights of others.



You must not violate the privacy rights of others.



You must not upload, post or link to any material that is inappropriate, defamatory, infringing, obscene, vulgar, libelous, racially or ethnically, hateful, objectionable, or unlawful (or promotes or provides instructional information about illegal activities or communications that could cause harm or injury to any individual or group).



You must not post, upload or link to (a) anything that promotes or distributes pyramid schemes or chain letters or (b) other disruptive communications.



You must not upload or post anything that infringes any patent, trademark, copyright, trade secret or other intellectual property or other legal right of a third party without prior written permissions from the third party, which permissions you agree to disclose to us on our request. You must not download any material that you know or reasonably should know cannot be distributed legally.



You must not impersonate another person or entity, which includes not using anyone else’s username or password.



You must not use the App for any illegal or unauthorized purpose. This includes but is not limited to spamming.



You must abide by all Federal, State and local laws. If you are outside the United States you must comply with all local laws with respect to your online conduct and acceptable content as well as the export of data to the United States or to your country or residence.



You cannot modify or delete any content and communications of the App and/or of other individuals other than your own.



You must not transmit any viruses, worms, defects, or any items and/or code of a destructive nature.    



You must not transmit or allow access or distribution of any spiders, robots or other information gathering computer programs or routines or their equivalents on or in the App.



You must not interfere or disrupt the App or servers or networks connected with the App or disobey any requirements, procedures, policies or regulations of networks connected to the App.



You cannot create member accounts under any false or fraudulent pretenses (including by automated means).



You must not state or imply that any of your submitted and or posted content is endorsed by the App or PL47 Productions.



You must not retrieve, store or collect personal information about other users for any unauthorized purpose.    



You must not engage in any ad "slamming".



You cannot use the names or trademarks of the App or PL47 Productions, which you acknowledge here to be valid, subsisting and enforceable without impediment, and you will not expressly or by implication create the false or misleading impression that the App or PL47 Productions is associated with, or endorses, or is in any way connected with you or your content.



WHILE PL47 PRODUCTIONS EXPLICITLY PROHIBITS THE ABOVE CONDUCT, YOU ACKNOWLEDGE AND AGREE THAT YOU MAY BE EXPOSED TO SUCH CONDUCT AND CONTENT AND THAT YOU USE THE APP ENTIRELY AT YOUR OWN RISK, AND THAT PL47 PRODUCTIONS SHALL HAVE NO LIABILITY OF ANY KIND FOR SUCH CONDUCT.



YOU UNDERSTAND AND AGREE THAT IF YOU VIOLATE ANY OF THE RULES OF BEHAVIOR, PL47 PRODUCTIONS CAN TERMINATE YOUR USE OF THE APP IMMEDIATELY WITHOUT PRIOR NOTICE, WITHOUT ANY RIGHT OF REFUND, SET-OFF OR A HEARING.



4. Content  



As a registered user, you will be able to upload and post a great variety of content, including but not limited to text, audio, video, photographs, graphics and other materials ("Content"). This means that you have sole responsibility, not PL47 Productions, for all Content that you upload post, email, transmit or otherwise make available through the App, and to make sure that you are in compliance with the rules of behavior relating to such Content.



PL47 Productions shall have no liability of any kind with respect to any Content posted by you or other users of the App. You agree that you must assess and bear all risks associated with the use of any Content. In this regard, you may not rely on any Content created by other App users or otherwise created, distributed and displayed on any part of the App. PL47 Productions does not control or monitor the Content posted via the App and, as such, does not guarantee the accuracy, integrity or quality of such Content.



The Content created by you must be owned by you or you must have been granted prior permission to use it. PL47 Productions does not own any of the Content you post. By placing Content on the App you are providing PL47 Productions with a worldwide, royalty-free, unlimited, non-exclusive license to publicly display, modify, reproduce and distribute your Content on the App for editorial and promotional purposes of the App. PL47 Productions reserves the right to syndicate or promote this Content in connection with the promotion of the App and other services offered by PL47 Productions.



PL47 Productions shall have no responsibility for enforcing any rights you claim in any Content, which shall be your responsibility entirely, and you agree to hold harmless and indemnify PL47 Productions with respect to any claim you have that other users are reproducing or using your Content as well as for claims by other users that you are reproducing or using their Content without permission. PL47 Productions will not arbitrate, mediate or resolve any intellectual property or other disputes between users, and has no responsibility for doing so.



You acknowledge that PL47 Productions may or may not pre-screen Content but that PL47 Productions and its designees shall have the right, but not the obligation, in its sole discretion to pre-screen, refuse, move, delete, any Content that violates the Terms or is otherwise objectionable.



PL47 Productions may preserve and store your account information and Content if it believes in good faith that such preservation is necessary to comply with legal processes, respond to claims that the Content violates the rights of third parties, or to protect the rights, property or personal safety of PL47 Productions, its users and/or the public.



5. Copyright Infringement. PL47 Productions respects the intellectual property of others and we request our users do the same. In certain circumstances and at its discretion, PL47 Productions may, but is not obligated to disable, suspend and terminate the accounts of members who may be infringing on the rights of others. If you believe that your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have otherwise been violated please contact PL47 Productions’ Designated Agent at plysaght47@pl47productions.com with the following information:



1.An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest;  



2.A description of where the material you claim is infringing is located on the App;



3.Your name, address, telephone number, email address;  



4.A statement claiming that you have a good faith belief that the disputed use has not been authorized; 



5.A statement by you made under penalty of perjury, that the above information is accurate and that you are the copyright or intellectual property holder or our authorized to act on behalf of the holder.



Upon receipt of a proper notice of copyright infringement by our Designated Agent, we reserve the right to:



Remove or disable access to the infringing material;



Notify the content provider that we have removed or disabled access to the material; and



Terminate such content provider’s access to the App if he or she is a repeat offender.



If the content provider believes that the material that was removed (or to which access was disabled) is not infringing, or the content provider believes that it has the right to post and use such material from the copyright owner, the copyright owner’s agent, or, pursuant to the law, the content provider has the right to send a counter-notice containing the following information to the Designated Agent listed below:



A physical or electronic signature of the content provider;

Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or disabled;

A statement that the content provider has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material; and

The content provider’s name, address, telephone number, and, if available, email address, and a statement that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which the content provider’s address is located, or, if the content provider’s address is located outside the United States, for any judicial district in which PL47 Productions is located, and that such person or entity will accept service of process from the person who provided notification of the alleged infringement.



If a counter-notice is received by the Designated Agent, PL47 Productions may send a copy of the counter-notice to the original complaining party informing that person that PL47 Productions may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the content provider accused of committing infringement, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notice, at PL47 Productions’ discretion.



The Designated Agent[PHS1]  can be contacted by email or mail at the following address:



Plysaght47@pl47productions.com



6. Links



The App may provide, or third parties including PL47 Productions users, may provide links to other mobile applications, websites or resources. PL47 Productions has no control over these sites and content and therefore you acknowledge that PL47 Productions is not responsible for the availability of such links, resources and content, and does not endorse and is not responsible or liable for any content, advertising, products, or other materials made available on or from these linked applications or websites. You also acknowledge and agree that PL47 Productions is not responsible or liable, directly or indirectly, for any damage or loss caused by or alleged to have been caused by or in relation to the use of content, goods or services offered through these links or any failures and/or disruption to your computer system or mobile device that results from your use of any such links, or for any intellectual property or other third party claims relating to your posting or using such links. YOU AGREE THAT IF PL47 PRODUCTIONS REQUESTS YOU TO DISABLE ANY LINK YOU HAVE POSTED AND YOU FAIL TO DO SO WITHIN 24 HOURS AFTER RECEIVING THE REQUEST TO DISABLE, PL47 PRODUCTIONS HAS THE RIGHT TO DISABLE THE LINK WITHOUT NOTICE TO YOU.



7. Indemnity  



You agree to indemnify and hold PL47 Productions, its subsidies, affiliates, members, officers, agents, and other partners and employees, harmless from any and all loss, liability, claim, or demand, including actual attorney’s fees and costs, made by arising out of your use of the App in violation of the Terms and/or your violation of any rights of another.



8. Modification and Termination of the App  



PL47 Productions reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the App (or any part thereof) with or without notice. You agree that PL47 Productions will not be liable to you or any third party for any modification, suspension or discontinuance of the App.



9. Privacy Policy  



Personal and certain other information is subject to our Privacy Policy. As a condition of using the App you agree to the terms of the Privacy Policy, as it may be changed from time to time. Our Privacy Policy, which is incorporated here by reference, is located at https://www.tensecondsatatime.com.  You agree that your use of this App is subject to the Privacy Policy.



10. Registration



In order to use certain aspects of the App you are required to register. Registered users are subject to the following specific terms in addition to all of the other provisions in these Terms:



In consideration of your use of the App, you represent that you are of legal age to form a binding contract and are not a person barred from receiving services under the laws of the United States or other applicable jurisdiction.



You also agree to provide true, accurate, current and complete information as required in the App’s registration process. If you provide any information that is untrue, incomplete, not current or inaccurate, PL47 Productions has the right to suspend or terminate your account and refuse your current or future use of the App (or any portion thereof.)



You agree that PL47 Productions may, under certain circumstances and without prior notice immediately terminate your account, any associated username and/or access to the App. Cause for such termination shall include, but not be limited to: (i) breaches or violations of the Terms or other policies, guidelines or rules, (ii) extended periods of inactivity, (iii) your engagement in fraudulent or illegal activity, (iv) unexpected technical or security issues, and (v) requests by law enforcement or other government agencies. You also agree that any termination is in PL47 Productions’ sole discretion and that PL47 Productions will not be liable to you or any third party for any termination of your account, password, username, deletion of Content or access to the App.



You agree that your account is non-transferable and any rights to your account, password, username, terminate upon your death.



You are responsible for any activities that take place under your username and password. If you become aware of any unauthorized use of your password or account, or any other breach of security, please contact PL47 Productions immediately. It is up to you to maintain the confidentiality of your password and account. PL47 Productions is not responsible or liable for any loss or damage arising from your failure to comply with the provisions of these Terms.



11. Practices regarding Use and Storage



You acknowledge that PL47 Productions may establish general practices and limits regarding use of the App including, without limitation, the maximum number and size of videos you can upload, the maximum amount of storage space that will be allotted on our servers on your behalf, and the maximum number of times and duration for which you may access the App in a given time. You agree that PL47 Productions has no responsibility or liability for the deletion or failure to store or transmit any messages or communications or other Content maintained or transmitted by the App. You acknowledge and agree that PL47 Productions reserves the right to log off accounts and deactivate usernames and accounts that are inactive for an extended period of time. You further acknowledge that PL47 Productions has the right to modify these practices and limits from time to time.



12. Proprietary Rights



You acknowledge and agree that the App and any necessary software used in connection with the App ("Software") contain proprietary and confidential information that is the property of PL47 Productions and its licensors and is protected by applicable intellectual property and other laws. You further acknowledge and agree that content contained in advertisements or information presented to you through the App or advertisers may be protected by copyright, trademarks, service marks, patents or other proprietary rights and laws. Except as expressly authorized by PL47 Productions or its advertisers, you agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on the App or Software, in whole or in part. You also acknowledge PL47 Productions’ exclusive rights in the PL47 Productions trademarks and service marks.



13. Disclaimer of Warranties  



The use of the App is at your sole risk. The App is provided on an "as is" and "as available" basis. PL47 PRODUCTIONS EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.



PL47 PRODUCTIONS MAKES NO WARRANTY THAT (I) THE APP WILL MEET YOUR REQUIREMENTS., (2) THE APP WILL BE UNINTERRUPTED, TIMELY SECURE, OR ERROR-FREE, (3) THE RESULTS OF USING THE APP WILL BE ACCURATE OR RELIABLE, (4) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED THROUGH YOUR USE OF THE APP WILL MEET YOUR EXPECTATIONS, OR (5) THAT ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.



No advice or information, whether oral or written, obtained by you from PL47 Productions or through or from the App shall create a warranty not expressly stated in the Terms.



14. Limitation of Liability



You expressly understand and agree that PL47 Productions will not be liable to you for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses (even if PL47 Productions has been advised of the possibility of such damages), resulting from (a) the use or the inability to use the App, (b) unauthorized access to or alteration or alteration of your transmissions or data, (c) statements or conduct of any third party including advertisers on the App, (d) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the App, or (e) any other matter relating to the App, and in no event will PL47 Productions be liable for any amount of money over $100.00, which you acknowledge to be a fair and reasonable sum.



15. Disputes



If there is any dispute about or involving the App, you agree that any dispute shall be governed by the laws of the State of Ohio without regard to conflict of law provisions. YOU FURTHER AGREE THAT BINDING ARBITRATION, TO BE CONDUCTED IN CINCINNATI, OHIO, SHALL BE THE SOLE AND EXCLUSIVE VENUE FOR ANY CLAIMS OR DISPUTES RELATED TO THE APP. YOU EXPRESSLY WAIVE THE RIGHT TO MAINTAIN OR PARTICIPATE IN ANY CLASS ACTION LAWSUIT REGARDING THE APP.



16. General Information



These Terms constitutes the entire agreement between you and PL47 Productions and governs your use of the App. You may also be subject to additional terms and conditions, posted policies (including but not limited to the Privacy Policy), guidelines, or rules that may apply when you use or purchase certain elements of the App, affiliate or advertiser services, third-party content or third-party software.



The failure of PL47 Productions to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties’ intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.



The section titles in the Terms are for convenience only and have no legal or contractual effect.



Nothing contained in the Terms shall be construed to constitute either party as a partner, employee or agent of the other party, nor shall either party hold itself out as such. Each party has no right or authority to incur, assume or create, in writing or otherwise, any warranty, liability or other obligation of any kind, express or implied, in the name of or on behalf of the other party, it being intended by both parties that each shall remain independent contractors responsible for its own actions.



You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the App or the Terms must be filed within one year after such claim or cause of action arose or be forever barred.



Please report any violations of the Terms to support@pl47productions.com.


				</p>

				</div>
			);
	}
}

export default TermsOfService;