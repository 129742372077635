import React,  { Component } from 'react'
import GoogleLink from '../badge_new.png'
import BigPromoPicture from '../forsite.png'
import AppleLink from '../appstore.png'


class Home extends Component  {
	render() {
		return (
<div class='container hero'>
  
<div class="hero hero-landing">
	<div class="row valign-wrapper no-valign-wrapper-on-mobile">
	<div class="col l5 valign hero-text"><div class="container-wide">
<h1 class='orange-text'><b>The moral support app</b></h1>
<p class='black-text'>10 seconds at a Time is a simple way to send moral support messages 
to your friends and relatives facing difficult times, in a non-intrusive, motivation inducing way. 
The only way to view a message is to start and finish the 10 second timer. 

</p>

<h1 class='orange-text'><b>Why 10 seconds?</b></h1>
<p class='black-text'>To quote the Unbreakable Kimmy Schmidt, “You can stand anything for 10 seconds…Then you just start on a new 10 seconds.”
Experiencing long periods of intense pain, suffering, or emotional agony can often create feelings of defeat, isolation, and depression.
However, by breaking down our indefinite suffering into something definite, say 10 second periods, we can begin to reclaim the feeling of 
success, hope, and accomplishment. These feelings can lead to an increase in dopamine levels, which can lead to better moods, energy, and 
motivation.

As such, 10 Seconds at a Time was created to promote and encourage fighting small battles to achieve big wins. 
As you will see in the app, by starting and finishing the 10 second timer, not only will you have reclaimed a bit of hope, 
you will be rewarded with a supportive message either from your friends, family, or from our public support channel.</p>

 </div>
 </div>
 <div class="col l7 no-right-padding valign hero-img-col">
 <a href="https://wizlogo.com">
 <img src={BigPromoPicture} alt="Sign Up Now" class="hero-img responsive-img"></img>
 </a>
 </div>
 </div>
 </div>



  
    </div>

			);
	}
}



export default Home;